var config_secret = {
    apiKey: "AIzaSyAw-uZmAJOz-awRxcpt9QNrQi99Y2WHHwQ",
    authDomain: "react-spas-tvj.firebaseapp.com",
    databaseURL: "https://react-spas-tvj.firebaseio.com",
    projectId: "react-spas-tvj",
    storageBucket: "react-spas-tvj.appspot.com",
    messagingSenderId: "1029688541435"
  };


export const config = config_secret;

export default config;